import swal from "sweetalert";
import FIREBASE from "../config/FIREBASE";
import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
} from "../utils/dispatch/index";

import {
  ADMINS_LOGIN,
  ADMINS_LOGOUT,
  API_DONOR_DARAH,
  API_TIMEOUT,
  USERS,
} from "../utils";
import axios from "axios";

export const LOGIN_USER = "LOGIN_USER";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const loginUser = (email, password, setLoginProses, history) => {
  FIREBASE.auth()
    .signInWithEmailAndPassword(email, password)
    .then((res) => {
      FIREBASE.database()
        .ref(`users/${res.user.uid}`)
        .once("value")
        .then((resDB) => {
          // Signed in
          if (resDB.val()) {
            //Pengecekan admin ke data base manek
            axios({
              method: "post",
              url: API_DONOR_DARAH + "admin/login",
              timeout: API_TIMEOUT,
              data: { email: email, uid: resDB.val().uid },
            })
              .then((response) => {
                if (response.status !== 200) {
                  // ERROR
                } else {
                  //BERHASIL

                  const data = response.data;

                  //cek apakah yang bersangkutan terdata di sim obat
                  if (data.accessToken) {
                    swal(
                      "Success",
                      resDB.val().nama + " berhasil login",
                      "success"
                    );
                    const dataUser = resDB.val();
                    let curr = {};
                    curr.nama = dataUser.nama;

                    curr.status = data.role;
                    curr.avatar = dataUser.avatar;
                    curr.perangkatdaerah = data.perangkatdaerah;
                    curr.email = data.email;
                    curr.desa = data.desa;
                    curr.nohp = data.nomorTelepon;

                    curr.uid = dataUser.uid;
                    localStorage.setItem("user", JSON.stringify(curr));

                    history.push("/admin/dashboard");
                    setLoginProses(false);
                  } else {
                    setLoginProses(false);
                    swal("Gagal", " Anda bukan admin", "error");
                  }
                }
              })
              .catch((error) => {
                // ERROR
                //  console.log(creds.email, resDB().uid);
                setLoginProses(false);
                swal("Gagal", " Anda bukan admin" + error.message, "error");
              });

            //dispatchSuccess(dispatch, LOGIN_USER, resDB.val());
          } else {
            setLoginProses(false);
            swal("Gagal", " Anda bukan admin", "error");
          }
        })
        .catch((error) => {
          setLoginProses(false);
          swal("Failed", error, "error");
        });
    })
    .catch((error) => {
      var errorMessage = error.message;
      setLoginProses(false);
      swal("Failed", errorMessage, "error");
    });
};

export const loginUser2 = (email, password) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGIN_USER);
    axios({
      method: "post",
      url: ADMINS_LOGIN,
      timeout: API_TIMEOUT,
      data: { username: email, password: password },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, LOGIN_USER, "error");
        } else {
          //BERHASIL
          if (response.data.success == 0) {
            swal("Failed", "email dan password anda salah", "error");
            dispatchError(dispatch, LOGIN_USER, "error");
          } else {
            dispatchSuccess(
              dispatch,
              LOGIN_USER,
              response.data.data ? response.data.data : []
            );
          }
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, LOGIN_USER, error);
        swal("Failed", "Anda Bukan Admin", "error");
      });
  };
};

export const checkLogin = (history) => {
  if (window.localStorage.getItem("user")) {
    const user = JSON.parse(window.localStorage.getItem("user"));

    FIREBASE.database()
      .ref(`users/${user.uid}`)
      .once("value")
      .then((resDB) => {
        if (resDB.val()) {
          history.push({ pathname: "/admin/dashboard" });
        } else {
          history.push({ pathname: "/login" });
        }
      })
      .catch((error) => {
        history.push({ pathname: "/login" });
      });
  } else {
    history.push({ pathname: "/login" });
  }
};

export function logoutUser(history) {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  axios.defaults.headers.common["Authorization"] = "";

  axios.delete(API_DONOR_DARAH + "admin/logout", {
    credentials: "include",
  });

  history.push({ pathname: "/login" });
}
