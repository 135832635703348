/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";

import UserProfile from "views/UserProfile.js";

import Upload_Tab from "views/Upload_Tab";

import PermintaanDonor from "views/PermintaanDonor";
import donatur from "./assets/img/donator.png";
import blood from "./assets/img/blood_donasi.png";
import blood2 from "./assets/img/blood_test.png";
import blood3 from "./assets/img/blood-pressure.png";
import blood4 from "./assets/img/donor.png";
import blood5 from "./assets/img/donation.png";
import usersku from "./assets/img/teamwork.png";
import ResponPermintaan from "views/ResponPermintaan";
import ListDonatur from "views/ListDonatur";
import UsersGolda from "views/UsersGolda";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: blood3,
    component: Dashboard,
    layout: "/admin",
    sidebar: true,
    user: true,
  },
  {
    path: "/permintaan-donor",
    name: "Permintaan Donor",
    rtlName: "ملف تفي للمستخدم",
    icon: blood5,
    component: PermintaanDonor,
    layout: "/admin",
    sidebar: true,
    user: true,
  },

  {
    path: "/upload",
    name: "Daftar Permintaan",
    rtlName: "إخطارات",
    icon: blood,
    component: Upload_Tab,
    layout: "/admin",
    sidebar: true,
    user: true,
  },

  {
    path: "/progres",
    name: "Respon Permintaan",
    rtlName: "قائمة الجدول",
    icon: blood2,
    component: ResponPermintaan,
    layout: "/admin",
    sidebar: true,
    user: true,
  },

  {
    path: "/users-satudata",
    name: "Users Donor Darah",
    rtlName: "ملف تفي للمستخدم",
    icon: usersku,
    component: UsersGolda,
    layout: "/admin",
    sidebar: true,
    user: false,
  },
  {
    path: "/donatur",
    name: "Donatur",
    rtlName: "ملف تفي للمستخدم",
    icon: donatur,
    component: ListDonatur,
    layout: "/admin",
    sidebar: true,
    user: true,
  },

  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تفي للمستخدم",
    icon: blood4,
    component: UserProfile,
    layout: "/admin",
    sidebar: true,
    user: true,
  },
];
export default routes;
