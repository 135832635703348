export const API_SATUDATA = "http://localhost:4000/api/";

export const API_DONOR_DARAH = "https://donordarah.rotendaokab.go.id/api/";
//'http://localhost:4000/api/';
//'https://satudata.rotendaokab.go.id/api/';

export const ADMINS_LOGIN = API_DONOR_DARAH + "admin/login";
export const ADMINS_LOGOUT = API_DONOR_DARAH + "admin/logout";
export const ADMINS_TOKEN = API_DONOR_DARAH + "admin/token";

export const LOG_APP = API_DONOR_DARAH + "logapp/";

export const API_TIMEOUT = 120000;

export const DOMAIN = "https://donordarah.rotendaokab.go.id/uploads/";
