import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
  Progress,
} from "reactstrap";

import axios from "axios";
import ReactMde from "react-mde";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { formatDate } from "utils/func";
import { toProperCase } from "utils/func";
import Success from "../assets/img/success.png";
import Phone from "../assets/img/phone-call.png";

import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { API_DONOR_DARAH } from "utils";

function ResponPermintaan() {
  const domain = "https://satudata.rotendaokab.go.id/uploads/";
  const [tabelRequest, setTabelRequest] = useState([]);

  const [modalOpen2, setModalOpen2] = useState(false);

  const history = useHistory();

  const [id_unit, setId_Unit] = useState("");
  const [tanggal, setTanggal] = useState("");

  const [jumlah, setJumlah] = useState(0);

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [listDokumen, setListDokumen] = useState([]);
  const [tahun, setTahun] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "Respon Permintaan");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
    //ambilListDokumen();
    refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser) {
      ambilRequest(currentUser.uid);
    }
  }, [currentUser]);

  const ambilRequest = (uid) => {
    axios({
      method: "get",
      url: API_DONOR_DARAH + "donationrespon/" + uid,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelRequest(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleSubmit = async (event) => {
    if (!jumlah || !tanggal) {
      swal("Info", "Semua data harus diisi", "info");
      setSaveLoading(false);
      return;
    }

    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    const data = {
      jumlahKantung: jumlah,
      tanggalRealisasi: date_string,
      id: id_unit,
    };

    await axios({
      method: "patch",
      url: API_DONOR_DARAH + "donationrespon/" + id_unit,
      timeout: API_TIMEOUT,
      credentials: "include",
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR

          swal("Failed", "ada kesalahan pada server", "error");
        } else {
          //BERHASIL

          swal(
            "Success",
            "Anda berhasil mencatat pelaksanaan donor. Terima kasih atas donasinya",
            "success"
          );
          ambilRequest(currentUser.uid);
          onModalClose();
          setSaveLoading(false);
        }
      })
      .catch((error) => {
        // ERROR
        swal("Failed", "Ada kesalahan pada server", "error");
      });

    setSaveLoading(false);
  };

  const onModalClose = () => {
    setId_Unit("");
    setTanggal("");

    setJumlah(0);
    setModalOpen2(false);
  };

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Daftar Permintaan Donor{" "}
                  <Badge color="warning">{currentUser.nama} </Badge>
                </CardTitle>
                <div>
                  {" "}
                  <Input type="select">
                    <option value={"2021"}>2021</option>
                    <option value={"2022"}>2022</option>
                    <option value={"2023"}>2023</option>
                    <option value={"2024"}>2024</option>
                  </Input>
                </div>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama Penerima</th>
                      <th>Golongan Darah</th>
                      <th>Tanggal Donor</th>

                      <th>Tempat Donor</th>
                      <th>Tujaun Donor</th>

                      <th>Kebutuhan (Kantung)</th>
                      <th>Status</th>
                      <th>Nama Pendonor</th>
                      <th>No HP Pendonor</th>
                      <th>Tanggal Mendonor</th>
                      <th>Jumlah donor (Kantung)</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelRequest &&
                      tabelRequest.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.namaPenerima}</td>
                          <td>{item.golonganDarah}</td>
                          <td>{formatDate(item.tanggalPermintaan)}</td>

                          <td>{item.lokasiDonor}</td>
                          <td>{item.alasanDonor}</td>

                          <td>{item.jumlahDibutuhkan}</td>
                          <td>
                            {item.jumlahKantung ? (
                              <img style={{ width: 40 }} src={Success} />
                            ) : (
                              "belum"
                            )}
                          </td>
                          <td>{item.nama}</td>
                          <td style={{ cursor: "pointer" }}>
                            {" "}
                            <img style={{ width: 20 }} src={Phone} />{" "}
                            {item.noHp}
                          </td>
                          <td>{formatDate(item.tanggalDonor)}</td>
                          <td>{item.jumlahKantung ? item.jumlahKantung : 0}</td>
                          <td>
                            {14 == 14 && (
                              <Button
                                color={14 === 14 ? "success" : "primary"}
                                size="sm"
                                onClick={() => {
                                  setId_Unit(item.responID);

                                  setModalOpen2(true);
                                }}
                              >
                                Donor
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          size="md"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className="headline-2">{"Pelaksanaan Pendonor"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Tanggal
              </Label>
              <Col md={9}>
                <Input
                  value={tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                  style={{ color: "black" }}
                  type="date"
                />
              </Col>
            </FormGroup>

            <div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Jumlah Kantung
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="number"
                      name="liga"
                      value={jumlah}
                      onChange={(event) => {
                        setJumlah(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen2(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {!saveLoading ? (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              ) : (
                <Button className="btn-rounded" size="sm" disabled>
                  {" "}
                  {"Upload ..."}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ResponPermintaan;
