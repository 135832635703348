import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
  Progress,
} from "reactstrap";

import axios from "axios";
import ReactMde from "react-mde";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { formatDate } from "utils/func";

import { toProperCase } from "utils/func";
import Success from "../assets/img/success.png";
import { downloadFile } from "utils/func";
import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { API_DONOR_DARAH } from "utils";

function Upload_Tab() {
  const domain = "https://satudata.rotendaokab.go.id/uploads/";
  const [tabelRequest, setTabelRequest] = useState([]);

  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();

  const [id_unit, setId_Unit] = useState("");
  const [status, setStatus] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [catatan, setCatatan] = useState("");

  const [nama, setNama] = useState("");
  const [noHp, setNoHp] = useState("");
  const [kesiapan, setKesiapan] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [listDokumen, setListDokumen] = useState([]);
  const [tahun, setTahun] = useState("");

  function findById(id) {
    const dok = listDokumen.filter((item) => {
      return item.id == id;
    });

    return dok[0].dokumen;
  }

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "Daftar Permintaan");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
    //ambilListDokumen();
    refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser) {
      ambilRequest();
    }
  }, [currentUser]);

  const ambilRequest = (id) => {
    axios({
      method: "get",
      url: API_DONOR_DARAH + "donationrequests",
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelRequest(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleSubmit = async (event) => {
    if (!nama || !tanggal || !nama || !noHp) {
      swal("Info", "Semua data harus diisi", "info");
      setSaveLoading(false);
      return;
    }

    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    const data = {
      nama: nama,
      pendonorID: currentUser.uid,
      tanggal: date_string,
      noHp: noHp,
      catatan: catatan,
      requestID: id_unit,
    };

    await axios({
      method: "post",
      url: API_DONOR_DARAH + "responpendonor",
      timeout: API_TIMEOUT,
      credentials: "include",
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR

          console.log(response.message);
          swal("Failed", "ada kesalahan pada server", "error");
        } else {
          //BERHASIL

          swal("Success", "Permintaan donor  berhasil direponse", "success");
          ambilRequest();
          onModalClose();
          setSaveLoading(false);
        }
      })
      .catch((error) => {
        // ERROR
        swal("Failed", "Ada kesalahan pada server", "error");
      });

    setSaveLoading(false);
  };

  const onModalClose = () => {
    setStatus("");
    setDokumen("");
    setId_Unit("");
    setTanggal("");
    setCatatan("");
    setModalOpen2(false);
  };

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Daftar Permintaan Donor</CardTitle>
                <div>
                  {" "}
                  <Input type="select">
                    <option value={"2021"}>2021</option>
                    <option value={"2022"}>2022</option>
                    <option value={"2023"}>2023</option>
                    <option value={"2024"}>2024</option>
                  </Input>
                </div>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama Penerima</th>
                      <th>Golongan Darah</th>
                      <th>Tanggal Donor</th>
                      <th>Status</th>

                      <th>Tujaun Donor</th>
                      <th>Tempat Donor</th>
                      <th>Kebutuhan (Kantung)</th>
                      <th>Total Donor(Kantung)</th>
                      <th>Jumlah Response</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelRequest &&
                      tabelRequest.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.namaPenerima}</td>
                          <td>{item.golonganDarah}</td>
                          <td>{formatDate(item.tanggalPermintaan)}</td>
                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {Math.round(
                                      (item.totalKantung /
                                        item.jumlahDibutuhkan) *
                                        100
                                    )}
                                    % terpenuhi{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.totalKantung /
                                      item.jumlahDibutuhkan) *
                                      100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.totalKantung /
                                      item.jumlahDibutuhkan) *
                                    100
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td>{item.lokasiDonor}</td>
                          <td>{item.alasanDonor}</td>
                          <td>{item.jumlahDibutuhkan}</td>
                          <td>{item.totalKantung}</td>
                          <td>{item.jumlahRespon}</td>
                          <td>
                            {14 == 14 && (
                              <Button
                                color={14 === 14 ? "success" : "primary"}
                                size="sm"
                                onClick={() => {
                                  setId_Unit(item.requestID);

                                  setModalOpen2(true);
                                }}
                              >
                                Respon
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          size="md"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className="headline-2">{"Respons Pendonor"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Tanggal
              </Label>
              <Col md={9}>
                <Input
                  value={tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                  style={{ color: "black" }}
                  type="date"
                />
              </Col>
            </FormGroup>

            <div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Kesiapan
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="select"
                      name="liga"
                      value={kesiapan}
                      onChange={(event) => {
                        setKesiapan(event.target.value);
                      }}
                    >
                      <option value={""}>- pilih -</option>

                      <option value={"datang ke lokasi"}>
                        {"datang ke lokasi"}
                      </option>
                      <option value={"dijemput"}>{"dijemput"}</option>
                    </Input>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Nama
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="text"
                      name="liga"
                      value={nama}
                      onChange={(event) => {
                        setNama(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Nomor Handphone
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="text"
                      name="liga"
                      value={noHp}
                      onChange={(event) => {
                        setNoHp(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md={3} className="text-md-right" for="markdown-editor">
                  Catatan Pendonor
                </Label>
                <Col md={9}>
                  <Input
                    value={catatan}
                    onChange={(e) => setCatatan(e.target.value)}
                    style={{ color: "black" }}
                    type="textarea"
                    rows="4"
                  />
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen2(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {!saveLoading ? (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              ) : (
                <Button className="btn-rounded" size="sm" disabled>
                  {" "}
                  {"Upload ..."}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Upload_Tab;
