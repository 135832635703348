/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { logoutUser } from 'actions/AuthAction';
import { useHistory } from 'react-router-dom';
import App from 'chat/components/App';

import messageImg from '../../assets/img/message.png';

function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState('navbar-transparent');
  const history = useHistory();
  const [modalOpen2, setModalOpen2] = useState(false);

  const [currentUser, setCurrentUser] = useState('');
  React.useEffect(() => {
    window.addEventListener('resize', updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('resize', updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor('bg-white');
    } else {
      setcolor('navbar-transparent');
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor('navbar-transparent');
    } else {
      setcolor('bg-white');
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
      } else {
        console.log('Tidak ada data user di localStorage.');
      }
    }
  }, []);
  return (
    <>
      <Navbar className={classNames('navbar-absolute', color)} expand='lg'>
        <Container fluid>
          <div className='navbar-wrapper'>
            <div
              className={classNames('navbar-toggle d-inline', {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className='navbar-toggler-bar bar1' />
                <span className='navbar-toggler-bar bar2' />
                <span className='navbar-toggler-bar bar3' />
              </NavbarToggler>
            </div>
            <NavbarBrand href='#pablo' onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className='ml-auto' navbar>
              <InputGroup className='search-bar'>
                <Button color='link' onClick={toggleModalSearch}>
                  <i className='tim-icons icon-zoom-split' />
                  <span className='d-lg-none d-md-block'>Search</span>
                </Button>
              </InputGroup>
              <div
                style={{
                  width: 80,
                  height: 40,
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => setModalOpen2(true)}
              >
                <img
                  src={messageImg}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain', // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                  }}
                />
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: '#fd5c63',
                    borderRadius: 6,
                    position: 'absolute',
                    right: -3,
                    top: -1,
                  }}
                ></div>
              </div>

              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color='default'
                  data-toggle='dropdown'
                  nav
                >
                  <div className='notification d-none d-lg-block d-xl-block' />
                  <i className='tim-icons icon-sound-wave' />
                  <p className='d-lg-none'>Notifications</p>
                </DropdownToggle>
                <DropdownMenu className='dropdown-navbar' right tag='ul'>
                  <NavLink tag='li'>
                    <DropdownItem className='nav-item'>Upload DPA</DropdownItem>
                  </NavLink>
                  <NavLink tag='li'>
                    <DropdownItem className='nav-item'>
                      Rencana Kerja Perangkat Daerah
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag='li'>
                    <DropdownItem className='nav-item'>
                      Renstra Perangkat Daerah
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color='default'
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className='photo'>
                    <img
                      alt='...'
                      src={
                        currentUser && currentUser.avatar
                          ? currentUser.avatar
                          : require('assets/img/anime3.png')
                      }
                    />
                  </div>
                  <b className='caret d-none d-lg-block d-xl-block' />
                  <p className='d-lg-none'>Log out</p>
                </DropdownToggle>
                <DropdownMenu className='dropdown-navbar' right tag='ul'>
                  <NavLink tag='li'>
                    <DropdownItem className='nav-item'>Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag='li'>
                    <DropdownItem
                      className='nav-item'
                      onClick={() => setModalOpen2(true)}
                    >
                      Chatting
                    </DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag='li' />
                  <NavLink tag='li'>
                    <DropdownItem
                      className='nav-item'
                      onClick={() => logoutUser(history)}
                    >
                      Log out
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className='separator d-lg-none' />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName='modal-search'
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder='SEARCH' type='text' />
          <button
            aria-label='Close'
            className='close'
            onClick={toggleModalSearch}
          >
            <i className='tim-icons icon-simple-remove' />
          </button>
        </ModalHeader>
      </Modal>

      <Modal
        size='lg'
        isOpen={modalOpen2}
        toggle={() => setModalOpen2(false)}
        contentClassName='p-0'
        modalClassName='modal-slide-in event-sidebar'
      >
        <ModalHeader toggle={() => setModalOpen2(false)}>
          <div>
            <div className='headline-2'>{'Percakapan - Satu Data'}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <App id={currentUser && currentUser.uid} />
        </ModalBody>
      </Modal>
    </>
  );
}

export default AdminNavbar;
