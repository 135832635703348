/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { toProperCase } from "utils/func";
import { logApp } from "utils/func";
import axios from "axios";
import { API_TIMEOUT } from "utils";
import swal from "sweetalert";
import { API_DONOR_DARAH } from "utils";

function PermintaanDonor() {
  const [currentUser, setCurrentUser] = useState("");
  const history = useHistory();

  const [nama, setNama] = useState("");
  const [golda, setGolda] = useState("");
  const [desa, setDesa] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [alasan, setAlasan] = useState("");
  const [lokasi, setLokasi] = useState("");
  const [jumlah, setJumlah] = useState("");

  const [listDesa, setListDesa] = useState([]);
  const [listDesaPilihan, setListDesaPilihan] = useState([]);

  const [listKecamatan, setListKecamatan] = useState([]);

  const [listLokasi, setListLokasi] = useState([]);
  const [listAlasan, setListAlasan] = useState([]);
  const [listGolda, setListGolda] = useState([]);

  useEffect(() => {
    getListDesa();
    getListKecamatan();
    getListAlasan();
    getListLokasi();
    getListGolda();
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Permintaan Donor");
      } else {
        history.push("/login");
      }
    }
  }, []);

  const ubahKecamatan = (kecamatan) => {
    var listDesaPilih = listDesa.filter((item) => {
      return item.kecamatan == kecamatan;
    });

    var listDesaPilihFilter = listDesaPilih.map((item, index) => {
      return { id: index, label: item.desa, value: item.desa };
    });
    setListDesaPilihan(listDesaPilihFilter);
    setDesa("");
    setKecamatan(kecamatan);
  };

  const getListGolda = async () => {
    var data1 = [];

    try {
      const response = await axios.get(API_DONOR_DARAH + "bloodtypes");
      data1 = response.data.data;

      if (data1.length > 0) {
        setListGolda(data1);
      } else {
        setListGolda([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getListDesa = async () => {
    var data1 = [];

    try {
      const response = await axios.get(API_DONOR_DARAH + "desa");
      data1 = response.data.data;

      if (data1.length > 0) {
        setListDesa(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getListLokasi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(API_DONOR_DARAH + "lokasi");
      data1 = response.data.data;

      if (data1.length > 0) {
        setListLokasi(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const kirimPermintaan = async () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    const data = {
      namaPenerima: nama,
      penerimaID: currentUser.uid,
      tanggalPermintaan: date_string,
      bloodTypeID: golda,

      jumlahDibutuhkan: jumlah,
      status: "belum_terpenuhi",
      desa: desa,

      alasan: alasan,
      lokasi: lokasi,
    };

    axios({
      method: "post",
      url: API_DONOR_DARAH + "donationrequests",
      timeout: API_TIMEOUT,

      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR

          swal("Gagal", " Data Permintaan gagal diinput", "failed");
          return "error";
        } else {
          //BERHASIL

          swal("Sukses", "Data Permintaan Donor berhasil diinput", "success");

          setDesa("");
          setKecamatan("");
          setLokasi("");
          setAlasan("");
          setJumlah("");
          setNama("");
          setTanggal("");
          setGolda("");
        }
      })
      .catch((error) => {
        // ERROR

        swal("Gagal", " Data Permintaan Donor gagal diinput", "error");
      });
  };

  const handleKirim = () => {
    if (nama && alasan && golda && desa && lokasi && jumlah) {
      kirimPermintaan();
    } else {
      swal("Info", "Semua data harus diisi", "error");
    }
  };

  const getListAlasan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(API_DONOR_DARAH + "tujuandonor");
      data1 = response.data.data;

      if (data1.length > 0) {
        setListAlasan(data1);
      } else {
        setListAlasan([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getListKecamatan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(API_DONOR_DARAH + "kecamatan");
      data1 = response.data.data;

      if (data1.length > 0) {
        var dataku = data1.map((item, index) => {
          return { id: index, label: item.kecamatan, value: item.kecamatan };
        });
        setListKecamatan(dataku);
      } else {
        setListKecamatan([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Formulir Permintaan Donor Darah</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Nama Pasien</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="nama"
                          type="text"
                          value={nama}
                          onChange={(e) => setNama(e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <label>Asal Kecamatan</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="Company"
                          type="select"
                          value={kecamatan}
                          onChange={(e) => ubahKecamatan(e.target.value)}
                          required
                        >
                          <option value={""}>-pilih kecamatan-</option>
                          {listKecamatan.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label>Asal Desa</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="Company"
                          type="select"
                          value={desa}
                          onChange={(e) => setDesa(e.target.value)}
                          required
                        >
                          <option value={""}>-pilih desa-</option>
                          {listDesaPilihan.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <label>Tanggal Donor</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="Company"
                          type="date"
                          value={tanggal}
                          onChange={(e) => setTanggal(e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <label>Tujuan Donor</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder=""
                          type="select"
                          value={alasan}
                          onChange={(e) => setAlasan(e.target.value)}
                          required
                        >
                          <option value={""}>-pilih alasan-</option>
                          {listAlasan.map((item) => (
                            <option value={item.id}>{item.alasan}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <label>Jumlah Kantung</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="jumlah"
                          type="number"
                          value={jumlah}
                          onChange={(e) => setJumlah(e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <label>Golongan Darah</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="Company"
                          type="select"
                          value={golda}
                          onChange={(e) => setGolda(e.target.value)}
                          required
                        >
                          <option value={""}>-pilih golda-</option>
                          {listGolda.map((item) => (
                            <option value={item.bloodTypeID}>
                              {item.golonganDarah}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <label>Lokasi Donor</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="Company"
                          type="select"
                          value={lokasi}
                          onChange={(e) => setLokasi(e.target.value)}
                          required
                        >
                          <option value={""}>-pilih lokasi donor-</option>
                          {listLokasi.map((item) => (
                            <option value={item.id}>{item.lokasi}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  onClick={handleKirim}
                >
                  Kirim
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={currentUser && currentUser.avatar}
                    />
                    <h5 className="title">{currentUser && currentUser.nama}</h5>
                  </a>
                  <p className="description">
                    {currentUser && currentUser.status == 1 ? "admin" : "user"}
                  </p>
                </div>
                <div className="card-description">
                  Akun ini merupakan akun Rote Smart Service yang
                  mengintegrasikan berbagai layanan pemerintah dalam sebuah
                  portal layanan
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PermintaanDonor;
