import { checkLogin } from "actions/AuthAction";
import { loginUser } from "../actions/AuthAction";
import React, { useEffect, useState } from "react";
//import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import Logo from "../assets/img/logorote.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const dispatch = useDispatch();
  const history = useHistory();

  const [loginProses, setLoginProses] = useState(false);

  // const { loginLoading, loginResult, loginError, checkLoginResult } =
  //   useSelector((state) => state.AuthReducer);

  useEffect(() => {
    checkLogin(history);
  }, []);

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    setLoginProses(true);
    event.preventDefault();

    if (email && password) {
      //action login
      //loginAdmin();
      loginUser(email, password, setLoginProses, history);
    } else {
      swal("Failed", "Maaf Email dan Password Harus Diisi", "error");
    }
  }

  // useEffect(() => {
  // if (checkLoginResult) {
  //   history.push('/admin/dashboard');
  // }

  //   if (loginResult) {
  //     history.push('/admin/dashboard');
  //   }
  // }, [loginResult]);

  return (
    <div className="content">
      <Row className="justify-content-center mt-5">
        <Col md="4" className="mt-5">
          <img
            src={Logo}
            className="rounded mx-auto d-block"
            alt="logo"
            style={{ width: 70, marginBottom: 20 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span style={{ marginTop: -10 }}>Kabupaten Rote Ndao</span>
          </div>
          <Card>
            <CardHeader tag="h4">
              DONOR DARAH -{" "}
              <span style={{ fontSize: 12 }}>
                {" "}
                Setetes Darah Kita Nyawa bagi Sesama
              </span>
            </CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <FormGroup>
                  <Label for="email">Email Address</Label>
                  <Input
                    type="text"
                    name="text"
                    value={email}
                    placeholder="Enter Email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    placeholder="Enter Password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormGroup>
                {loginProses ? (
                  <Button color="primary" type="submit" disabled>
                    <Spinner size="sm" color="light" /> Loading
                  </Button>
                ) : (
                  <Button color="primary" type="submit">
                    Login
                  </Button>
                )}
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
