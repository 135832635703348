import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useConversations } from '../contexts/ConversationsProvider';
import { DOMAIN } from 'utils';
import { useContacts } from 'chat/contexts/ContactsProvider';
import avatar from '../../assets/img/default-avatar.png';
import avatar2 from '../../assets/img/logorote.png';

export default function Conversations({ id }) {
  const { conversations, selectConversationIndex } = useConversations();
  const { contacts } = useContacts();

  function findContactProfileById(id) {
    const contact = contacts.find((contact) => contact.id === id);
    return contact ? contact.profile : undefined;
  }

  function countUnreadMessages(messages) {
    let unreadCount = 0;

    messages.forEach((message) => {
      if (!message.readStatus && message.sender !== id) {
        unreadCount++;
      }
    });

    return unreadCount;
  }

  return (
    <ListGroup variant='flush'>
      {conversations.map((conversation, index) => (
        <ListGroup.Item
          key={index}
          action
          onClick={() => selectConversationIndex(index)}
          active={conversation.selected}
        >
          {conversation.recipients.length > 1 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <img
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  marginRight: 10,
                }}
                src={avatar2}
              />
              <span style={{}}>{conversation.nama}</span>
              {countUnreadMessages(conversation.messages) > 0 && (
                <div
                  style={{
                    backgroundColor: '#fd5c63',
                    color: 'white',
                    width: 15,
                    height: 15,
                    borderRadius: 7,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 5,
                  }}
                >
                  <span style={{ fontSize: 8 }}>
                    {' '}
                    {countUnreadMessages(conversation.messages)}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  marginRight: 10,
                  position: 'relative',
                }}
                src={
                  findContactProfileById(conversation.recipients[0].id) != '-'
                    ? DOMAIN +
                      findContactProfileById(conversation.recipients[0].id)
                    : avatar
                }
              />
              <span style={{}}>{conversation.recipients[0].name}</span>
              {countUnreadMessages(conversation.messages) > 0 && (
                <div
                  style={{
                    backgroundColor: '#fd5c63',
                    color: 'white',
                    width: 15,
                    height: 15,
                    borderRadius: 7,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 5,
                  }}
                >
                  <span style={{ fontSize: 8 }}>
                    {countUnreadMessages(conversation.messages)}
                  </span>
                </div>
              )}
            </div>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}
