import React, { useState, useCallback, useEffect } from 'react';

import { useConversations } from '../contexts/ConversationsProvider';
import { timeDifference } from 'utils/func';

import {
  Input,
  Form,
  Button,
  Badge,
  Label,
  FormGroup,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
} from 'reactstrap';

import { DOMAIN } from 'utils';
import avatar from '../../assets/img/default-avatar.png';

import { useContacts } from 'chat/contexts/ContactsProvider';
import { SEND_READ_STATUS } from 'chat/utils';
import { API_TIMEOUT } from 'chat/utils';
import axios from 'axios';
import swal from 'sweetalert';
import { DELETE_MESSAGE } from 'chat/utils';

export default function OpenConversation({ id }) {
  const [text, setText] = useState('');
  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);
  const { sendMessage, selectedConversation } = useConversations();
  const [msgRender, setMsgRender] = useState(selectedConversation.messages);

  useEffect(() => {
    if (selectedConversation) {
      setMsgRender(selectedConversation.messages);
    }
  }, [selectedConversation]);

  const { contacts } = useContacts();
  const [modalOpen, setModalOpen] = useState(false);

  const [unreadmsg, setunreadmsg] = useState('');

  function countUnreadMessages(messages) {
    let unreadCount = 0;
    let unreadMessages = [];

    messages.forEach((message) => {
      if (!message.readStatus && message.msgId) {
        unreadCount++;
        unreadMessages.push({ messageid: message.msgId, userid: id });
      }
    });

    return {
      unreadCount: unreadCount,
      unreadMessages: unreadMessages,
    };
  }

  useEffect(() => {
    setunreadmsg(countUnreadMessages(selectedConversation.messages));
  }, [selectedConversation]);

  useEffect(() => {
    if (unreadmsg && unreadmsg.unreadCount > 0) {
      sendReadStatus();
    }
  }, [unreadmsg]);

  const sendReadStatus = () => {
    axios({
      method: 'post',
      url: SEND_READ_STATUS,
      timeout: API_TIMEOUT,
      data: unreadmsg.unreadMessages,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  function handleSubmit(e) {
    e.preventDefault();

    sendMessage(
      selectedConversation.recipients.map((r) => r.id),
      text,
      selectedConversation.conversationId
    );
    setText('');
  }

  function findContactProfileById(id) {
    const contact = contacts.find((contact) => contact.id === id);
    return contact ? contact.profile : undefined;
  }

  const deleteMsg = (msgid) => {
    const data = msgRender;
    const msgFil = data.filter((item) => {
      return item.msgId != msgid;
    });
    setMsgRender(msgFil);
  };

  const deleteMessage = (msgid) => {
    deleteMsg(msgid);

    axios({
      method: 'delete',
      url: DELETE_MESSAGE + msgid,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <div className='d-flex flex-column flex-grow-1'>
      {selectedConversation.recipients.length > 1 ? (
        <span style={{ cursor: 'pointer' }} onClick={() => setModalOpen(true)}>
          {' '}
          {selectedConversation.nama}{' '}
          <Badge color='success'>
            {selectedConversation.recipients.length}
          </Badge>{' '}
          anggota
        </span>
      ) : (
        <span style={{ color: 'blue' }}>
          {selectedConversation.recipients.map((r) => r.name).join(', ')}
        </span>
      )}
      <div className='flex-grow-1 overflow-auto'>
        <div className='d-flex flex-column align-items-start justify-content-end px-3'>
          {msgRender.map((message, index) => {
            const lastMessage = msgRender.length - 1 === index;
            return (
              <div
                ref={lastMessage ? setRef : null}
                key={index}
                className={`my-1 d-flex flex-column ${
                  message.fromMe
                    ? 'align-self-end align-items-end'
                    : 'align-items-start'
                }`}
              >
                <div
                  className={`rounded px-2 py-1 ${
                    message.fromMe ? 'bg-primary text-white' : 'border'
                  }`}
                  style={{ position: 'relative' }}
                >
                  {message.text}
                  {message.fromMe && (
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        borderRadius: 8,
                        backgroundColor: 'GrayText',
                        position: 'absolute',
                        right: -7,
                        top: -7,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => deleteMessage(message.msgId)}
                    >
                      <span style={{ fontSize: 10 }}>x</span>
                    </div>
                  )}
                </div>
                <div
                  className={`text-muted small ${
                    message.fromMe ? 'text-right' : ''
                  }`}
                  style={{ marginTop: 5 }}
                >
                  <img
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      marginRight: 10,
                    }}
                    src={
                      findContactProfileById(message.sender) != '-'
                        ? DOMAIN + findContactProfileById(message.sender)
                        : avatar
                    }
                  />
                  {message.fromMe ? 'You' : message.senderName}
                  <span style={{ marginLeft: 5, marginRight: 5 }}>at</span>
                  <span style={{ color: 'green', fontSize: 10 }}>
                    {timeDifference(message.createdAt)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Input
            type='textarea'
            required
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={{
              height: '75px',
              resize: 'none',
              color: '#808080',
              padding: 10,
            }}
          />

          <Button
            type='submit'
            color='warning'
            style={{ height: '75px', resize: 'none' }}
          >
            Send
          </Button>
        </div>
      </Form>
      <Modal
        size='sm'
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        contentClassName='p-0'
        modalClassName='modal-slide-in event-sidebar'
      >
        <ModalHeader toggle={() => setModalOpen(false)}>
          <Label>Anggota Group</Label>
        </ModalHeader>
        <ModalBody>
          {selectedConversation.recipients.map((contact) => (
            <div>
              <FormGroup check key={contact.id}>
                <img
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                  src={
                    findContactProfileById(contact.id) != '-'
                      ? DOMAIN + findContactProfileById(contact.id)
                      : avatar
                  }
                />
                <Label>
                  <span>
                    <span /> {contact.name}
                  </span>
                </Label>
              </FormGroup>
            </div>
          ))}
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center', paddingBottom: 10 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Button
              className='btn-rounded'
              size='sm'
              color='warning'
              onClick={() => setModalOpen(false)}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
