import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
  Progress,
} from "reactstrap";

import axios from "axios";

import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { formatDate } from "utils/func";
import { toProperCase } from "utils/func";
import Success from "../assets/img/success.png";
import Phone from "../assets/img/phone-call.png";

import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { API_DONOR_DARAH } from "utils";

function ListDonatur() {
  const domain = "https://satudata.rotendaokab.go.id/uploads/";
  const [tabelRequest, setTabelRequest] = useState([]);

  const [modalOpen2, setModalOpen2] = useState(false);

  const history = useHistory();

  const [id_unit, setId_Unit] = useState("");
  const [tanggal, setTanggal] = useState("");

  const [jumlah, setJumlah] = useState(0);

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [listGolda, setListGolda] = useState([]);
  const [golda, setGolda] = useState("");
  const [tahun, setTahun] = useState("");
  const [listData, setListData] = useState([]);

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    getListGolda();
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "Donatur");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
    //ambilListDokumen();
    refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser) {
      ambilRequest(currentUser.uid);
    }
  }, [currentUser]);

  useEffect(() => {
    if (golda) {
      const listSelect = listData.filter((item) => {
        return item.bloodTypes === golda;
      });
      setTabelRequest(listSelect);
    }
  }, [golda]);

  const ambilRequest = (uid) => {
    axios({
      method: "get",
      url: API_DONOR_DARAH + "donatur",
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          setListData(data);

          setGolda("A(+)");
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getListGolda = async () => {
    var data1 = [];

    try {
      const response = await axios.get(API_DONOR_DARAH + "bloodtypes");
      data1 = response.data.data;

      if (data1.length > 0) {
        setListGolda(data1);
      } else {
        setListGolda([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    if (!jumlah || !tanggal) {
      swal("Info", "Semua data harus diisi", "info");
      setSaveLoading(false);
      return;
    }

    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    const data = {
      jumlahKantung: jumlah,
      tanggalRealisasi: date_string,
      id: id_unit,
    };

    await axios({
      method: "patch",
      url: API_DONOR_DARAH + "donationrespon/" + id_unit,
      timeout: API_TIMEOUT,
      credentials: "include",
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR

          swal("Failed", "ada kesalahan pada server", "error");
        } else {
          //BERHASIL

          swal(
            "Success",
            "Anda berhasil mencatat pelaksanaan donor. Terima kasih atas donasinya",
            "success"
          );
          ambilRequest(currentUser.uid);
          onModalClose();
          setSaveLoading(false);
        }
      })
      .catch((error) => {
        // ERROR
        swal("Failed", "Ada kesalahan pada server", "error");
      });

    setSaveLoading(false);
  };

  const onModalClose = () => {
    setId_Unit("");
    setTanggal("");

    setJumlah(0);
    setModalOpen2(false);
  };

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Daftar Donatur <Badge color="warning">Kab. Rote Ndao</Badge>
                </CardTitle>
                <FormGroup>
                  <label>Golongan Darah</label>
                  <Input
                    defaultValue="Creative Code Inc."
                    placeholder="Company"
                    type="select"
                    value={golda}
                    onChange={(e) => setGolda(e.target.value)}
                    required
                  >
                    <option value={""}>-pilih golda-</option>
                    {listGolda.map((item) => (
                      <option value={item.golonganDarah}>
                        {item.golonganDarah}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>Tahun</label>
                  <Input type="select">
                    <option value={"2021"}>2021</option>
                    <option value={"2022"}>2022</option>
                    <option value={"2023"}>2023</option>
                    <option value={"2024"}>2024</option>
                  </Input>
                </FormGroup>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama Donatur</th>
                      <th>Golongan Darah</th>
                      <th>Asal Desa</th>

                      <th>Nomor Telpon</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelRequest &&
                      tabelRequest.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.nama}</td>
                          <td>{item.bloodTypes}</td>

                          <td>{item.desa}</td>

                          <td>{item.noHp}</td>
                          <td>
                            {14 == 14 && (
                              <Button
                                color={14 === 14 ? "success" : "primary"}
                                size="sm"
                                onClick={() => {
                                  setId_Unit(item.responID);

                                  setModalOpen2(true);
                                }}
                              >
                                <img
                                  style={{ width: 20, marginRight: 5 }}
                                  src={Phone}
                                />
                                Telpon
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          size="md"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className="headline-2">{"Pelaksanaan Pendonor"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Tanggal
              </Label>
              <Col md={9}>
                <Input
                  value={tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                  style={{ color: "black" }}
                  type="date"
                />
              </Col>
            </FormGroup>

            <div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Jumlah Kantung
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="number"
                      name="liga"
                      value={jumlah}
                      onChange={(event) => {
                        setJumlah(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen2(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {!saveLoading ? (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              ) : (
                <Button className="btn-rounded" size="sm" disabled>
                  {" "}
                  {"Upload ..."}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ListDonatur;
