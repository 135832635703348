import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useContacts } from '../contexts/ContactsProvider';
import { DOMAIN } from 'utils';

import avatar from '../../assets/img/default-avatar.png';
export default function Contacts() {
  const { contacts } = useContacts();

  return (
    <ListGroup variant='flush'>
      {contacts.map((contact) => (
        <ListGroup.Item key={contact.id}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: 30,
                height: 30,
                borderRadius: 15,
                marginRight: 10,
              }}
              src={contact.profile != '-' ? DOMAIN + contact.profile : avatar}
            />
            <span style={{ whiteSpace: 'nowrap' }}>{contact.name}</span>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}
